import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import Avatar from '../../../components/Avatar';
import Dropdown, { DropdownMenu, DropdownToggle } from '../../../components/bootstrap/Dropdown';
import { noMenuPages } from '../../../menu';
import { getHeaderSimple } from '../../../utils';
import useUpdateHeader from '../../../hooks/useUpdateHeader';

const CommonHeaderImage = () => {
	const navigate = useNavigate();

	const [state, setState] = useState(false);
	const [msgCount, setMsgCount] = useState(0);
	const { updateHeaderStatus, setUpdateHeaderStatus } = useUpdateHeader();
	const [userName, setUserName] = useState(localStorage.getItem('USER_FULL_NAME'));
	const [userImage, setUserImage] = useState(localStorage.getItem('USER_IMAGE'));

	useEffect(() => {
		setMsgCount(0);
		if (updateHeaderStatus) {
			getUserData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateHeaderStatus]);

	const getUserData = async () => {
		fetch(
			`${process.env.REACT_APP_API_URL}users/headerInfo/${localStorage.getItem('USER_ID')}`,
			{
				headers: getHeaderSimple(),
			},
		)
			.then((response) => response.json())
			.then((rawData) => {
				if (rawData.message === 'Ok') {
					localStorage.setItem('USER_FULL_NAME', rawData.content.userFullName);
					localStorage.setItem('USER_IMAGE', rawData.content.userImage);
					setUserName(rawData.content.userFullName);
					setUserImage(rawData.content.userImage);
				}
				setUpdateHeaderStatus(false);
			});
	};

	const closeSession = async () => {
		const body = {
			userId: localStorage.getItem('USER_ID'),
			userName: localStorage.getItem('USER_FULL_NAME'),
		};

		fetch(`${process.env.REACT_APP_API_URL}auth/closeSession`, {
			method: 'POST',
			headers: getHeaderSimple(),
			body: JSON.stringify(body),
		})
			.then((response) => response.json())
			.then((rawData) => {
				// eslint-disable-next-line no-empty
				if (rawData.message === 'Ok') {
				}
			});
	};

	return (
		<div
			className='col d-flex align-items-center cursor-pointer'
			onClick={() => setState(!state)}
			role='presentation'>
			<div className='me-3'>
				<div className='text-end'>
					<div className='fw-bold fs-6 mb-0'>{userName}</div>
				</div>
			</div>

			<div className='col-auto'>
				<Dropdown>
					<DropdownToggle hasIcon={false}>
						{/* eslint-disable-next-line react/jsx-props-no-spreading */}
						<div className='position-relative'>
							<Avatar src={userImage} size={48} />
							{!!msgCount && (
								<span className='position-absolute top-15 start-85 translate-middle badge rounded-pill bg-danger'>
									{msgCount}{' '}
									<span className='visually-hidden'>unread messages</span>
								</span>
							)}
							<span className='position-absolute top-85 start-85 translate-middle badge border border-2 border-light rounded-circle bg-success p-2'>
								<span className='visually-hidden'>Online user</span>
							</span>
						</div>
					</DropdownToggle>
					<DropdownMenu isAlignmentEnd size='lg' className='py-0 overflow-hidden'>
						<div className='row g-0'>
							<div
								className={classNames(
									'col-12',
									'p-4',
									'd-flex justify-content-center',
									'fs-8',
									'border-info',
								)}
								style={{ backgroundColor: '#000', color: '#fff' }}
								role='presentation'
								onClick={() => {
									closeSession();
									localStorage.removeItem('USER_TOKEN');
									localStorage.removeItem('USER_ID');
									localStorage.removeItem('USER_FULL_NAME');
									localStorage.removeItem('USER_TENANT');
									localStorage.removeItem('STATUS');
									localStorage.removeItem('USER_IMAGE');
									localStorage.removeItem('USER_CONDO_NAME');
									localStorage.removeItem('USER_CONDO_ALIAS');
									localStorage.removeItem('DOORMAN_PERMISSION');

									navigate(`../${noMenuPages.loaderLaunch.path}`, {
										replace: true,
									});
								}}>
								Cerrar sesión
							</div>
						</div>
					</DropdownMenu>
				</Dropdown>
			</div>
		</div>
	);
};

export default CommonHeaderImage;

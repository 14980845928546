import React, { lazy } from 'react';
import { demoPages, noMenuPages, multiCondosPages, doormanPages } from '../menu';
import Login from '../pages/presentation/auth/Login';
import TERMS from '../pages/presentation/auth/terms';

const AUTH = {
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
	NO_AUTHORIZATE_PAGE: lazy(() => import('../pages/presentation/auth/noAuthorizatePage')),
	LOADERLAUNCH: lazy(() => import('../pages/presentation/auth/LoaderLaunch')),
	ADMIN: lazy(() => import('../pages/presentation/demo-pages/AdminRegisterPage')),
	PRICINGTABLE: lazy(() => import('../pages/presentation/pricing/PricingTablePage')),
	RECOVERYPASSWORD: lazy(() => import('../pages/presentation/auth/RecoverPassword')),
	RECOVERYPASSWORDSTEPTWO: lazy(() =>
		import('../pages/presentation/auth/RecoverPasswordStepTwo'),
	),
	INIVTE: lazy(() => import('../pages/presentation/auth/Invite')),
};

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/dashboard/DashboardPage')),
	RESIDENTREQUEST: lazy(() => import('../pages/presentation/demo-pages/RequestsPage')),
	RESIDENTS: lazy(() => import('../pages/presentation/demo-pages/ResidentsPage')),
	INVITATIONS: lazy(() => import('../pages/presentation/demo-pages/InvitationPage')),
	RESIDENTBALANCE: lazy(() => import('../pages/presentation/demo-pages/ResidentsBalancePage')),
	MOROSOS: lazy(() => import('../pages/common/CommonDefaulterEvents')),
	VEHICLE: lazy(() => import('../pages/vehicle/vehiclePage')),
	PETS: lazy(() => import('../pages/presentation/demo-pages/PetsPage')),
	COMITE: lazy(() => import('../pages/presentation/demo-pages/CommitteePage')),
	MARKET: lazy(() => import('../pages/presentation/demo-pages/MarketPage')),
	QUIZ: lazy(() => import('../pages/presentation/demo-pages/QuizPage')),
	STICKERS: lazy(() => import('../pages/presentation/demo-pages/StickersPage')),
	AREAS: lazy(() => import('../pages/presentation/demo-pages/AreaPage')),
	SUPPLIER: lazy(() => import('../pages/supplier/supplierPage')),
	GUESTS: lazy(() => import('../pages/presentation/demo-pages/GuestsPage')),
	BLACKLIST: lazy(() => import('../pages/presentation/demo-pages/BlackListPage')),
	EVENTSCALENDAR: lazy(() => import('../pages/event/eventCalendarPage')),
	ACTIVITIES: lazy(() => import('../pages/event/activitiesPage')),
	// ACCESSCONTROL
	GOALS: lazy(() => import('../pages/goals/goalsPage')),
	COMMUNIQUE: lazy(() => import('../pages/goals/communiquePage')),
	EXTERNALSERVICES: lazy(() => import('../pages/common/CommonServicesEvents')),
	INGRESS: lazy(() => import('../pages/presentation/demo-pages/IncomePage')),
	AGREEMENTS: lazy(() => import('../pages/presentation/demo-pages/AgreementsPage')),
	EGRESS: lazy(() => import('../pages/finance/EgressPage')),
	BALANCE: lazy(() => import('../pages/presentation/demo-pages/BalancePage')),
	REPORTER: lazy(() => import('../pages/presentation/demo-pages/ReporterPage')),
	CHARGES: lazy(() => import('../pages/presentation/demo-pages/ChargePage')),
	ACCOUNTS: lazy(() => import('../pages/presentation/demo-pages/AccountsPage')),
	ADJUSTMENT: lazy(() => import('../pages/presentation/demo-pages/AdjustmentPage')),
	BUDGET: lazy(() => import('../pages/presentation/demo-pages/BudgetPage')),
	DOCUMENTS: lazy(() => import('../pages/presentation/demo-pages/DocumentsPage')),
	ACCOUNTSETUP: lazy(() => import('../pages/presentation/demo-pages/CondoPage')),
	ADMINISTRATOR: lazy(() => import('../pages/presentation/demo-pages/AdministratorPage')),
	REPORTS: lazy(() => import('../pages/presentation/demo-pages/ReportsPage')),
	WORKERDIRECTORY: lazy(() => import('../pages/presentation/demo-pages/WorkerDirectoryPage')),
	PARKING: lazy(() => import('../pages/presentation/demo-pages/ParkingPage')),
	INVITEADMIN: lazy(() => import('../pages/presentation/demo-pages/InviteAdminPage')),
};

const MULTICONDO = {
	WORKSPACE: lazy(() => import('../pages/workspace/WorkSpacePage')),
};

const DOORMAN = {
	USER_PAGE: lazy(() => import('../pages/presentation/demo-pages/DoormanPage')),
};

const presentation = [
	/**
	 * Auth Page
	 */
	{
		path: noMenuPages.page404.path,
		element: <AUTH.PAGE_404 />,
		exact: true,
	},
	{
		path: noMenuPages.loaderLaunch.path,
		element: <AUTH.LOADERLAUNCH />,
		exact: true,
	},
	{
		path: noMenuPages.login.path,
		element: <Login />,
		exact: true,
	},
	{
		path: noMenuPages.invite.path,
		element: <AUTH.INIVTE />,
		exact: true,
	},
	{
		path: noMenuPages.signUp.path,
		element: <Login isSignUp />,
		exact: true,
	},
	{
		path: noMenuPages.pricingTable.path,
		element: <AUTH.PRICINGTABLE />,
		exact: true,
	},
	{
		path: noMenuPages.administrator.path,
		element: <AUTH.ADMIN />,
		exact: true,
	},
	{
		path: noMenuPages.recoverPasswordStepOne.path,
		element: <AUTH.RECOVERYPASSWORD />,
		exact: true,
	},
	{
		path: noMenuPages.recoverPasswordStepTwo.path,
		element: <AUTH.RECOVERYPASSWORDSTEPTWO />,
		exact: true,
	},
	{
		path: noMenuPages.termsAndConditionsApp.path,
		element: <TERMS />,
		exact: true,
	},

	/**
	 * Menu
	 */
	{
		path: demoPages.dashboard.path,
		element: <LANDING.DASHBOARD />,
		exact: true,
	},
	{
		path: demoPages.crmDashboard.subMenu.residentRequest.path,
		element: <LANDING.RESIDENTREQUEST />,
		exact: true,
	},
	{
		path: demoPages.crmDashboard.subMenu.resident.path,
		element: <LANDING.RESIDENTS />,
		exact: true,
	},
	{
		path: demoPages.crmDashboard.subMenu.residentBalance.path,
		element: <LANDING.RESIDENTBALANCE />,
		exact: true,
	},
	{
		path: demoPages.crmDashboard.subMenu.morosos.path,
		element: <LANDING.MOROSOS />,
		exact: true,
	},
	{
		path: demoPages.crmDashboard.subMenu.reports.path,
		element: <LANDING.REPORTS />,
		exact: true,
	},
	{
		path: demoPages.crmDashboard.subMenu.invitations.path,
		element: <LANDING.INVITATIONS />,
		exact: true,
	},
	{
		path: demoPages.crmDashboard.subMenu.vehicle.path,
		element: <LANDING.VEHICLE />,
		exact: true,
	},
	{
		path: demoPages.crmDashboard.subMenu.gridBoxed.path,
		element: <LANDING.PETS />,
		exact: true,
	},
	{
		path: demoPages.crmDashboard.subMenu.reports.path,
		element: <AUTH.NO_AUTHORIZATE_PAGE />,
		exact: true,
	},
	{
		path: demoPages.summary.subMenu.comite.path,
		element: <LANDING.COMITE />,
		exact: true,
	},
	{
		path: demoPages.summary.subMenu.market.path,
		element: <LANDING.MARKET />,
		exact: true,
	},
	{
		path: demoPages.summary.subMenu.quiz.path,
		element: <LANDING.QUIZ />,
		exact: true,
	},
	{
		path: demoPages.summary.subMenu.areas.path,
		element: <LANDING.AREAS />,
		exact: true,
	},
	{
		path: demoPages.summary.subMenu.supplier.path,
		element: <LANDING.SUPPLIER />,
		exact: true,
	},
	{
		path: demoPages.fluidSingle.subMenu.guests.path,
		element: <LANDING.GUESTS />,
		exact: true,
	},
	{
		path: demoPages.fluidSingle.subMenu.blackList.path,
		element: <LANDING.BLACKLIST />,
		exact: true,
	},
	{
		path: demoPages.listBoxed.subMenu.calendar.path,
		element: <LANDING.EVENTSCALENDAR />,
		exact: true,
	},
	{
		path: demoPages.listBoxed.subMenu.activities.path,
		element: <LANDING.ACTIVITIES />,
		exact: true,
	},
	{
		path: demoPages.listFluid.path,
		element: <AUTH.NO_AUTHORIZATE_PAGE />,
		exact: true,
	},
	{
		path: demoPages.editModern.subMenu.goals.path,
		element: <LANDING.GOALS />,
		exact: true,
	},
	{
		path: demoPages.editModern.subMenu.communique.path,
		element: <LANDING.COMMUNIQUE />,
		exact: true,
	},
	{
		path: demoPages.summary.subMenu.editBoxed.path,
		element: <LANDING.EXTERNALSERVICES />,
		exact: true,
	},
	{
		path: demoPages.editFluid.subMenu.ingress.path,
		element: <LANDING.INGRESS />,
		exact: true,
	},
	{
		path: demoPages.editFluid.subMenu.egress.path,
		element: <LANDING.EGRESS />,
		exact: true,
	},
	{
		path: demoPages.editFluid.subMenu.balance.path,
		element: <LANDING.BALANCE />,
		exact: true,
	},
	{
		path: demoPages.editFluid.subMenu.reporter.path,
		element: <LANDING.REPORTER />,
		exact: true,
	},
	{
		path: demoPages.crmDashboard.subMenu.agreemens.path,
		element: <LANDING.AGREEMENTS />,
		exact: true,
	},
	{
		path: demoPages.editFluid.subMenu.charges.path,
		element: <LANDING.CHARGES />,
		exact: true,
	},
	{
		path: demoPages.editFluid.subMenu.accounts.path,
		element: <LANDING.ACCOUNTS />,
		exact: true,
	},
	{
		path: demoPages.editFluid.subMenu.adjustment.path,
		element: <LANDING.ADJUSTMENT />,
		exact: true,
	},
	{
		path: demoPages.editFluid.subMenu.budget.path,
		element: <LANDING.BUDGET />,
		exact: true,
	},
	{
		path: demoPages.listFluid.subMenu.addStickers.path,
		element: <LANDING.STICKERS />,
		exact: true,
	},
	{
		path: demoPages.summary.subMenu.documents.path,
		element: <LANDING.DOCUMENTS />,
		exact: true,
	},
	{
		path: demoPages.summary.subMenu.condo.path,
		element: <LANDING.ACCOUNTSETUP />,
		exact: true,
	},
	{
		path: demoPages.editInModal.subMenu.administrators.path,
		element: <LANDING.ADMINISTRATOR />,
		exact: true,
	},
	{
		path: demoPages.crmDashboard.subMenu.workers.path,
		element: <LANDING.WORKERDIRECTORY />,
		exact: true,
	},
	{
		path: demoPages.summary.subMenu.parking.path,
		element: <LANDING.PARKING />,
		exact: true,
	},
	{
		path: demoPages.editInModal.subMenu.invite.path,
		element: <LANDING.INVITEADMIN />,
		exact: true,
	},

	/**
	 * MultiCondosMenu
	 */
	{
		path: multiCondosPages.workSpace.path,
		element: <MULTICONDO.WORKSPACE />,
		exact: true,
	},
	{
		path: multiCondosPages.users.path,
		element: <AUTH.PAGE_404 />,
		exact: true,
	},

	/**
	 * DoormanMenu
	 */
	{
		path: doormanPages.doorman.subMenu.doormanUsers.path,
		element: <DOORMAN.USER_PAGE />,
		exact: true,
	},
];

const contents = [...presentation];

export default contents;

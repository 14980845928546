import React from 'react';
import { createUseStyles } from 'react-jss';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Icon from '../../components/icon/Icon';
import Logo from '../../assets/logos/Domus_logo_white.png';

/**
 * Style
 */
const useStyles = createUseStyles({
	image: {
		maxHeight: '34px',
		objectFit: 'contain',
	},
});

const Brand = ({ asideStatus, setAsideStatus }) => {
	const classes = useStyles();

	return (
		<div className='brand'>
			<div className='brand-logo' style={{ margin: 'auto' }}>
				<h1 className='brand-title '>
					<Link to='dashboard' aria-label='Logo' alt='Domus'>
						<img src={Logo} className={classes.image} alt='' />
					</Link>
				</h1>
			</div>
			<button
				type='button'
				className='btn brand-aside-toggle'
				aria-label='Toggle Aside'
				onClick={() => setAsideStatus(!asideStatus)}
				style={{ backgroundColor: '#fff', borderRadius: '50px' }}>
				<Icon
					icon='KeyboardArrowLeft'
					className='brand-aside-toggle-close'
					style={{ color: '#000' }}
				/>
				<Icon
					icon='KeyboardArrowRight'
					className='brand-aside-toggle-open'
					style={{ color: '#000' }}
				/>
			</button>
		</div>
	);
};
Brand.propTypes = {
	asideStatus: PropTypes.bool.isRequired,
	setAsideStatus: PropTypes.func.isRequired,
};

export default Brand;
